.viewcard{
    justify-content: center;
    text-align: center;
    width: 400px;
    height: 600px;
    position: fixed; left: 500px; top: 200px; right: 500px;bottom: 80px;
}
h5{
    color: gray;
}
